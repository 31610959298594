<template>
    <v-dialog v-model="active" persistent :max-width="maxWidth">
      <v-card>
        <v-progress-linear
          v-if="loader"
          indeterminate
          color="primary"
          rounded
          height="6"
        ></v-progress-linear>
        <v-card-title class="pa-0 shadow-1">
          <v-img
            :src="require('@/assets/illustrations/bg_modal_card.svg')"
            cover
            position="left bottom"
            class="align-center"
            height="65px"
            v-if="withImageTitle"
          >
            <v-card-title class="font-weight-medium primary--text">
              {{ title }}
            </v-card-title>
          </v-img>
          <v-card-title class="font-weight-medium primary--text" v-else>
              {{ title }}
          </v-card-title>
        </v-card-title>
      
        <v-card-text>
          <slot></slot>
        </v-card-text>
    
        <v-divider></v-divider>
    
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="()=>closeModal(false)"
              >{{ closeText }}</v-btn
            >
          </v-hover>
      
          <v-hover v-slot:default="{ hover }" v-if="confirmBtn">
            <v-btn
              rounded
              :loading="loader"
              :disabled="disabled"
              color="success"
              :class="hover ? 'shadow-2' : 'shadow-4'"
              class="px-8"
              @click="()=> action()"
              >Confirmar</v-btn
            >
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: true,
    },
    action: {
      type: Function,
      required: false,
      default: () => {},
    },
    closeModal: {
      type: Function,
      required: true,
      default: () => {},
    },
    confirmBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    title:{
      type: String,
      required: true,
      default: 'Titulo Modal'
    },
    closeText:{
      type: String,
      required: false,
      default: 'Cerrar'
    },
    loader:{
      type: Boolean,
      require: false,
      default: false,
    },
    disabled:{
      type: Boolean,
      require: false,
      default: false,
    },
    maxWidth:{
      type: String,
      required: false,
      default: '600'
    },
    withImageTitle:{
      type: Boolean,
      require: false,
      default: true,
    }
  },
};
</script>