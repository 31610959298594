<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-select
          @change="() => {}"
          label="Tienda"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="store_id"
          :items="data_stores"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-menu
          v-model="menu_start"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_start"
              label="Desde"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="date_start"
            :max="date_end"
            @input="menu_start = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-menu
          v-model="menu_end"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_end"
              label="Hasta"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="date_end"
            :min="date_start"
            @input="menu_end = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-col cols="12" md="2">
      <v-card class="shadow-1 px-2 py-2">
        <span class="font-weight-bold">Total: </span>
        <div class="text-right">{{ getTotalAmount | currency }}</div>
      </v-card>
    </v-col>
    <div v-if="!loadingTable">
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :loading="loadingTable"
            :headers="headers"
            :items="getUnsoldCarts"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
            :items-per-page="per_page"
          >
            <template v-slot:[`item.crm`]="{ item }">
              <button @click="GoToCustomerDetails(item)" class="btn-link">
                Ir
              </button>
            </template>
            <template v-slot:[`item.products`]="{ item }">
              <button @click="OpenProductsModal(item)" class="btn-link">
                Ver
              </button>
            </template>
            <template v-slot:[`item.total_amount`]="{ item }">
              <div class="text-right">{{ item.total_amount | currency }}</div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="6" class="pagination-box">
          <v-pagination
            v-model="page"
            :length="Math.ceil(getUnsoldCarts.length / per_page)"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <div v-else class="d-flex justify-center">
      <v-progress-circular
        size="300"
        class="d-flex justify-center my-5"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <custom-modal
      v-if="showProductsModal"
      :active="showProductsModal"
      :closeModal="HandlerCloseModal"
      :title="'Productos'"
      max-width="800"
    >
      <v-data-table
        class="mt-5"
        :headers="headersProductsShoppingCart"
        :items="productsSelected"
        hide-default-footer
      >
        <template v-slot:[`item.value`]="{ item }">
          <div>
            {{ item.net_pvp | currency }}
          </div>
        </template>
      </v-data-table>
    </custom-modal>
  </v-container>
</template>

<script>
import CustomModal from "../CustomModal/customModal.vue";
import moment from "moment";
export default {
  components: {
    "custom-modal": CustomModal,
  },
  data() {
    return {
      showProductsModal: false,
      productsSelected: [],
      loadingItemData: false,
      loadingTable: false,
      menu_start: false,
      menu_end: false,
      date_start: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      date_end: moment().format("YYYY-MM-DD"),
      headers: [
        {
          text: "Fecha de creación",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Nombre del cliente",
          align: "center",
          sortable: false,
          value: "buyer_name",
          class: "primary--text",
        },
        {
          text: "ID de la cuenta",
          align: "center",
          sortable: false,
          value: "buyer_store_id",
          class: "primary--text",
        },
        {
          text: "Email cliente",
          align: "center",
          sortable: false,
          value: "buyer_email",
          class: "primary--text",
        },
        {
          text: "CRM",
          align: "center",
          sortable: false,
          value: "crm",
          class: "primary--text",
        },
        {
          text: "Monto total",
          align: "center",
          sortable: false,
          value: "total_amount",
          class: "primary--text",
        },
        {
          text: "Productos",
          align: "center",
          sortable: false,
          value: "products",
          class: "primary--text",
        },
      ],
      headersProductsShoppingCart: [
        {
          text: "Nombre",
          sortable: false,
          value: "name",
          align: "left",
          class: "primary--text",
        },
        {
          text: "SKU",
          sortable: false,
          value: "sku",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "quantity",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Valor",
          sortable: false,
          value: "value",
          align: "center",
          class: "primary--text",
        },
      ],
      data_stores: [],
      store_id: 1,
      paginate: {},
      page: 1,
      per_page: 20,
    };
  },

  created() {
    this.HandlerGetStore();
    this.HandlerGetUnsoldCart();
  },
  computed: {
    getUnsoldCarts() {
      const data = this.$store.getters["store/GET_UNSOLD_CARTS"];
      return data;
    },
    getTotalAmount() {
      const data = this.$store.getters["store/GET_UNSOLD_CARTS"];
      return data.reduce((acum, { total_amount }) => (acum += total_amount), 0);
    },
  },

  watch: {
    store_id() {
      this.HandlerGetUnsoldCart();
    },
    date_start() {
      this.HandlerGetUnsoldCart();
    },
    date_end() {
      this.HandlerGetUnsoldCart();
    },
  },

  methods: {
    async HandlerGetUnsoldCart() {
      try {
        this.loadingTable = true;
        const request = {
          store_id: this.store_id,
          fromDate: this.date_start,
          toDate: this.date_end,
        };
        await this.$store.dispatch("store/GET_UNSOLD_CART", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },

    OpenProductsModal(item) {
      this.productsSelected = item.shopping_cart_items;
      this.showProductsModal = true;
    },

    GoToCustomerDetails(item){
      this.$router.push({
        name: "Clientes",
        params: { clientId: item.buyer_email,  store_id: this.store_id },
      });
    },

    HandlerCloseModal() {
      this.showProductsModal = false;
      this.productsSelected = [];
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: "",
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data.filter((index) => {
          return index.b2b_active == false;
        });
      } catch (error) {
        console.log(error);
      }
    },

    HandlerReset() {
      this.store_id = 1;
      this.HandlerGetUnsoldCart();
    },
  },
};
</script>

<style>
.btn-link {
  color: #2196f3;
  text-decoration: underline;
}
.content-modal {
  margin: 14px 0 -14px 0;
}
.cost-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.modal-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 500;
}
.pagination-box {
  display: flex;
  justify-content: end;
}
</style>
