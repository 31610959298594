var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Tienda","filled":"","rounded":"","dense":"","items":_vm.data_stores,"item-text":"name","item-value":"id"},on:{"change":function () {}},model:{value:(_vm.store_id),callback:function ($$v) {_vm.store_id=$$v},expression:"store_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-small",attrs:{"label":"Desde","readonly":"","filled":"","rounded":"","dense":"","name":"ciudad","hide-details":""},model:{value:(_vm.date_start),callback:function ($$v) {_vm.date_start=$$v},expression:"date_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_start),callback:function ($$v) {_vm.menu_start=$$v},expression:"menu_start"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-us","max":_vm.date_end},on:{"input":function($event){_vm.menu_start = false}},model:{value:(_vm.date_start),callback:function ($$v) {_vm.date_start=$$v},expression:"date_start"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-small",attrs:{"label":"Hasta","readonly":"","filled":"","rounded":"","dense":"","name":"ciudad","hide-details":""},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_end),callback:function ($$v) {_vm.menu_end=$$v},expression:"menu_end"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-us","min":_vm.date_start},on:{"input":function($event){_vm.menu_end = false}},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-card',{staticClass:"shadow-1 px-2 py-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Total: ")]),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.getTotalAmount)))])])],1),(!_vm.loadingTable)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.getUnsoldCarts,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.per_page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.crm",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"btn-link",on:{"click":function($event){return _vm.GoToCustomerDetails(item)}}},[_vm._v(" Ir ")])]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"btn-link",on:{"click":function($event){return _vm.OpenProductsModal(item)}}},[_vm._v(" Ver ")])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(item.total_amount)))])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pagination-box",attrs:{"cols":"6"}},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.getUnsoldCarts.length / _vm.per_page),"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{staticClass:"d-flex justify-center my-5",attrs:{"size":"300","indeterminate":"","color":"primary"}})],1),(_vm.showProductsModal)?_c('custom-modal',{attrs:{"active":_vm.showProductsModal,"closeModal":_vm.HandlerCloseModal,"title":'Productos',"max-width":"800"}},[_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headersProductsShoppingCart,"items":_vm.productsSelected,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.net_pvp))+" ")])]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }